import clsx from "clsx"
import piece1 from "../assets/pieces/index-1.png"
import piece2 from "../assets/pieces/index-2.png"
import piece3 from "../assets/pieces/index-3.png"
import piece4 from "../assets/pieces/index-4.png"
import piece5 from "../assets/pieces/index-5.png"

const pieces = [
    piece1,
    piece2,
    piece3,
    piece4,
    piece5
]

export default function Piece({number, className}) {
    return (
        <img src={pieces[number - 1]} alt="Frictionless Piece"
             className={clsx("absolute hidden hd:block animate-bounce", className)}/>
    )
}

