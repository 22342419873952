import {useState, useEffect} from "react"
import {Transition} from "@headlessui/react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faArrowUp} from "@fortawesome/free-solid-svg-icons"
import clsx from "clsx"

export default function Back() {
    const [show, setShow] = useState(false)

    useEffect(() => {
        const handleScrollVisibility = () => {
            const documentHeight = document.body.scrollHeight;
            const currentScroll = window.scrollY + window.innerHeight;

            if(currentScroll + 200 > documentHeight) { // hide if at bottom of page
                setShow(false)
            } else if(window.scrollY > 500) { // show if scrolled down
                setShow(true)
            } else {
                setShow(false)
            }
        }

        window.addEventListener('scroll', handleScrollVisibility)

        return () => {
            window.removeEventListener('scroll', handleScrollVisibility)
        }
    })

    return (
        <Transition
            show={show}
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <button className={clsx("fixed bottom-4 right-4 rounded-lg bg-white px-3 pb-2 pt-2.5 shadow-lg sm:px-6 sm:pb-4 sm:pt-5 sm:bottom-8 sm:right-8")}
                    onClick={() => {
                        window.scrollTo({top: 0})
                    }}>
                <FontAwesomeIcon icon={faArrowUp} className="text-lg text-neutral-900"/>
            </button>
        </Transition>
    )
}
