const advisors = {
    "Gideon Greaves": {
        role: "Business Development Advisor ",
        department: "Managing Director CV VC Africa",
        image: require("./gideon.png"),
        linkedInURL: "https://www.linkedin.com/in/gideon-greaves-97535348/"
    },
    "Ken Trueba": {
        role: "Cybersecurity Advisor",
        department: "BD Certik, COO Cypher Core",
        image: require("./ken.png"),
        linkedInURL: "https://www.linkedin.com/in/uwu/"
    },
    "Nelson Lopez": {
        role: "Human Resources Advisor",
        department: "Global Head of HR at Gate.io",
        image: require("./nelson.png"),
        linkedInURL: "https://www.linkedin.com/in/nelsondslopez/"
    },
    "Devon Krantz": {
        role: "Management Advisor",
        department: "Founder Linum Labs, Creator ETHcapetown",
        image: require("./devon.png"),
        linkedInURL: "https://www.linkedin.com/in/devon-krantz-642b9495/"
    },
    "Marie Tatibouet": {
        role: "Marketing Advisor",
        department: "CMO of Tacan Labs, former CMO at Gate.io",
        image: require("./marie.png"),
        linkedInURL: "https://www.linkedin.com/in/marietatibouet/"
    }
}

export default advisors
