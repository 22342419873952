import Section from "./Section"
import frictionlessToken from "../assets/frictionless-token.png"
import {Description, SubTitle, Title} from "./Typing"

export default function Token() {
    return (
        <Section id="token" className="items-center justify-between gap-12 lg:flex-row">
            <div className="max-w-2xl flex-1 flex flex-col gap-16">
                <div>
                    <Title className="mb-16">
                        Fric-Token
                    </Title>
                    <Description>
                        Our FRIC token is the essence of our platform, providing access to services and securing
                        on-chain cryptocurrency transfers. It enables non-custodial, trustless switching of assets and
                        it offers access to post-token-launch fundraising markets for projects and investors.
                    </Description>
                </div>

                <div>
                    <SubTitle className="!text-2xl !sm:text-3xl !lg:text-4xl mb-2">
                        On the Platform
                    </SubTitle>
                    <Description>
                        The token's value is derived from platform traffic and its on-chain utility, which increases
                        with a higher stake in the token. It creates revenue from a portion of transaction taxes and
                        fees which are used to fund operations and reward token holders. The more FRIC you hold, the
                        more benefits you get and the more features will get unlocked.
                    </Description>
                </div>
            </div>
            <img src={frictionlessToken} className="max-w-xs lg:max-w-sm 2xl:max-w-lg" alt=""/>

        </Section>
    )
}
