import Section from "./Section"
import {SubTitle} from "./Typing"

function importAll(r) {
  return r.keys().map(r)
}

const images1 = importAll(require.context('../assets/companies/', false, /\.(png|jpe?g|svg)$/))
const images2 = importAll(require.context('../assets/companies/', false, /\.(png|jpe?g|svg)$/))

export default function Background() {
  return (
    <Section id="backgrounds" className="!min-h-0 !pt-0 pb-16">
      <SubTitle className="text-start mt-16 text-3xl sm:text-5xl lg:text-6xl">
        Our Background
      </SubTitle>

      <div className="w-full overflow-hidden mt-10 opacity-60">
        <div className="relative w-[250%] h-6 overflow-hidden sm:h-12 lg:h-16">
          <div
            className="absolute w-[250%] h-6 animate-scroll flex items-center justify-around left-0 gap-2 sm:gap-4 sm:h-8 lg:h-16">
            {images1.map((src, index) => {
              return (
                <div key={src + index} className="flex justify-center">
                  <img src={src} alt="Company" className="grayscale h-6 w-auto sm:h-8 lg:h-16"/>
                </div>
              )
            })}

            {images2.map((src, index) => {
              return (
                <div key={src + index * 2} className="flex justify-center">
                  <img src={src} alt="Company" className="grayscale h-6 w-auto sm:h-8 lg:h-16"/>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Section>
  )
}
