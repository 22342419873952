import platformPreview from "../assets/preview-create.png"
import Section from "./Section"
import {ButtonLink} from "./Button"
import {Description, Title} from "./Typing"

export default function Product() {
    return (
        <Section id="product" className="flex flex-col justify-center" secondary>
            <Title className="mb-20">
                Frictionless<br/>Platform
            </Title>
            <div className="flex flex-col gap-4 lg:gap-8 2xl:flex-row">
                <img src={platformPreview} alt="Screenshot of Platform" className="rounded-lg lg:max-w-4xl"/>

                <div className="flex flex-col justify-start items-start gap-4 lg:gap-8 lg:max-w-4xl 2xl:max-w-lg">
                    <Description className="sm:text-start">
                        Our mission is to give every token project, venture capitalist, market maker, and OTC desk
                        the tools they need to manage treasuries and tokens with confidence. Whether you’re handling
                        token treasuries, working with vesting schedules, or managing market-making budgets, our
                        Private OTC Switch and public OTC platform offer solutions for securing assets, stabilizing
                        prices, and optimizing deal flow.
                    </Description>

                    <div className="w-full flex flex-col gap-4 sm:flex-row 2xl:flex-col 2xl:w-auto">
                        <ButtonLink className="text-center" href="https://beta.frictionless.network" newTab={false}>
                            Test our Beta
                        </ButtonLink>
                    </div>
                </div>
            </div>
        </Section>
    )
}
