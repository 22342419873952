import {useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCopy, faCheck} from "@fortawesome/free-solid-svg-icons"
import webm from "../assets/fric-render.webm"
import fallback from "../assets/render-fallback.png"
import Section from "./Section"
import Header from "./Header"
import {Description, SubTitle, Title} from "./Typing"
import {ButtonLink} from "./Button"

export default function Home() {
    const [copied, setCopied] = useState(false)

    const copy = async () => {
        await navigator.clipboard.writeText('0x23Fa3Aa82858E7aD1F0f04352f4bb7F5E1BbFb68')
        setCopied(true)
        setTimeout(() => setCopied(false), 1000)
    }

    return (
        <div className="relative w-screen min-h-screen">
            <Header/>
            <Section id="home">
                <div className="flex flex-col items-center justify-between gap-8 flex-1 mt-32 xl:flex-row">
                    <div className="w-full max-w-4xl">
                        <Title className="mb-8 text-blue-700">
                            Frictionless
                        </Title>

                        <SubTitle>
                            For the liberation of Token liquidations
                        </SubTitle>

                        <Description className="mt-8">
                            With Frictionless, a revolutionary
                            platform that empowers projects and investors to securely and efficiently transfer
                            Cryptocurrency in a unique, decentralized and trustless manner using smart contracts, that
                            circumvent assets market price impacts.
                        </Description>

                        <div className='flex flex-col justify-center items-center gap-4 mt-8 sm:flex-row lg:justify-start'>
                            <ButtonLink className='w-full max-w-sm sm:w-64'
                            href="https://app.uniswap.org/tokens/ethereum/0x23fa3aa82858e7ad1f0f04352f4bb7f5e1bbfb68?inputCurrency=ETH">
                                Buy FRIC
                            </ButtonLink>

                            <ButtonLink className='w-full max-w-sm sm:w-64'
                            href="https://www.dextools.io/app/en/ether/pair-explorer/0x69f4a2dfec7dd5ab64b916a11a7e96f6d46e9ca4">
                                Chart
                            </ButtonLink>
                        </div>

                        <Description
                            className='w-full !text-sm sm:!text-base mt-8 flex gap-2 justify-center items-center px-2 lg:justify-start lg:px-0'>
                            <span className='font-medium'>CA:</span>
                            <span className='text-ellipsis overflow-hidden'>0x23Fa3Aa82858E7aD1F0f04352f4bb7F5E1BbFb68</span>
                            <FontAwesomeIcon icon={copied ? faCheck : faCopy}
                                             fixedWidth
                                             onClick={copied ? undefined : copy}
                                             className='hover:text-neutral-100 hover:cursor-pointer'
                            />
                        </Description>
                    </div>

                    <video className="w-80 sm:max-w-sm xl:w-auto xl:max-w-lg"
                           loop
                           autoPlay
                           muted>
                        <source src={webm} type="video/webm" />
                        <img src={fallback} alt="Still Frame"/>
                    </video>
                </div>
            </Section>
        </div>
    )
}
