import Section from "./Section"
import Piece from "./Piece"
import advisors from "../assets/people/advisors"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faLinkedin} from "@fortawesome/free-brands-svg-icons"
import {SubTitle} from "./Typing"


export default function Relations() {
    return (
        <Section id="relations" secondary className="py-12">
            <SubTitle className="text-start mt-8">
                Advisors
            </SubTitle>

            <div className="max-w-5xl grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-12">
                {Object.entries(advisors).map(([name, {image, role, department, linkedInURL}]) => {
                    return (
                        <div key={name}>
                            <img src={image} className="rounded-lg" alt={name}/>
                            <p className="text-lg text-white font-medium mt-4">
                                {name}
                                <a href={linkedInURL} className="ml-2" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon className="hover:text-blue-600" icon={faLinkedin}/>
                                </a>
                            </p>
                            <p className="text-neutral-400 font-medium">{role}</p>
                            <p className="text-neutral-400 font-extralight break-words">{department}</p>
                        </div>
                    )
                })}
            </div>

            {/*<SubTitle className="text-start mt-16 text-3xl sm:text-5xl lg:text-6xl">*/}
            {/*    Our Background*/}
            {/*</SubTitle>*/}

            {/*<div className="w-full overflow-hidden mt-10 opacity-60">*/}
            {/*    <div className="relative w-[250%] h-6 overflow-hidden sm:h-12 lg:h-16">*/}
            {/*        <div*/}
            {/*            className="absolute w-[250%] h-6 animate-scroll flex items-center justify-around left-0 gap-2 sm:gap-4 sm:h-12 lg:h-16">*/}
            {/*            {images1.map((src, index) => {*/}
            {/*                return (*/}
            {/*                    <div key={src + index} className="flex justify-center">*/}
            {/*                        <img src={src} alt="Company" className="grayscale h-6 w-auto sm:h-12 lg:h-16"/>*/}
            {/*                    </div>*/}
            {/*                )*/}
            {/*            })}*/}

            {/*            {images2.map((src, index) => {*/}
            {/*                return (*/}
            {/*                    <div key={src + index * 2} className="flex justify-center">*/}
            {/*                        <img src={src} alt="Company" className="grayscale h-6 w-auto sm:h-12 lg:h-16"/>*/}
            {/*                    </div>*/}
            {/*                )*/}
            {/*            })}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <Piece number={1} className="w-[40rem] -top-80 self-end -right-8 2xl:block"/>
        </Section>
    )
}
