const partner = {
    "iPal": {
        "url": "https://www.ipal-network.com/",
        "image": require("./ipal.png"),
    },
    "ApeBond": {
        "url": "https://ape.bond/",
        "image": require("./apebond.png"),
    },
    "Patex": {
        "url": "https://patex.io/",
        "image": require("./patex.png"),
    },
    "Utu": {
        "url": "https://utu.io/",
        "image": require("./utu.png"),
    },
    "Arthera": {
        "url": "https://arthera.net/",
        "image": require("./arthera.png"),
    },
    'Sea Token': {
        url: "https://sea.earth/",
        "image": require("./sea.png"),
    },
    'EVDC': {
        url: "https://evdc.network/",
        "image": require("./evdc.png"),
    },
    "Forward": {
        "url": "https://forwardprotocol.io/",
        "image": require("./forward.png"),
    },
    "Suite3": {
        "url": "https://www.home3suite.com/",
        "image": require("./home3.png"),
    },
    "Nadmah": {
        "url": "https://nadmah.co/",
        "image": require("./nadmah.png"),
    },
    "DefenderBot": {
        "url": "https://defenderbot.tech/",
        "image": require("./defender.png"),
    },
    "MetaGrinders": {
        "url": "https://x.com/metagrindersx",
        "image": require("./metagrinders.png"),
    },
    "Mightyyy": {
        "url": "https://www.mightylabs.io/",
        "image": require("./mighty.png"),
    }
}

export default partner
