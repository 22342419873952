import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faLinkedin} from "@fortawesome/free-brands-svg-icons"
import Section from "./Section"
import team from "../assets/people/team"
import {SubTitle} from "./Typing"


export default function Team() {
    return (
        <Section id="team" className="py-12">
            <SubTitle className="text-start">
                Team
            </SubTitle>

            <div className="max-w-6xl grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-12">
                {Object.entries(team).map((
                    [name, {image, role, department, displayName, linkedInURL}]
                ) => {
                    return (
                        <div key={name}>
                            <img src={image} className="rounded-lg" alt={name}/>
                            <p className="text-lg text-white font-medium mt-4">
                                {displayName || name}
                                {
                                    linkedInURL &&
                                    <a href={linkedInURL} className="ml-2" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon className="hover:text-blue-600" icon={faLinkedin}/>
                                    </a>
                                }
                            </p>
                            <p className="text-neutral-400 font-medium">{role}</p>
                            <p className="text-neutral-400 font-extralight">{department}</p>
                        </div>
                    )
                })}
            </div>
        </Section>
    )
}
