const team = {
    "Milan Jandik": {
        "role": "CEO (Founder)",
        "department": "Team Lead",
        "image": require("./milan.png"),
        "linkedInURL": "https://www.linkedin.com/in/milan-jandik/"
    },
    "Achuth Krishna": {
        "role": "CFO",
        "department": "Head of Finance",
        "image": require("./achuth.png"),
        "linkedInURL": "https://www.linkedin.com/in/achuth-k-chandran-b17880102/"
    },
    "Gokar Nier": {
        "role": "Chief Creative Officer",
        "department": "Head of Design",
        "image": require("../placeholder.png"),
        "linkedInURL": ""
    },
    "Zachary Cogan": {
        "role": "Business Development",
        "department": "Partnerships and Relations",
        "image": require("./zachary.png"),
        "linkedInURL": "https://www.linkedin.com/in/zachary-cogan-721974a9/"
    },
    "Yuval Ardenbaum": {
        "role": "Business Development",
        "department": "Partnerships & Growth",
        "image": require("./yuval.png"),
        "linkedInURL": "https://www.linkedin.com/in/yuval-ardenbaum/"
    },
    "Kevin Vernooij": {
        "role": "Business Development",
        "department": "Marketing & Partnership Relations",
        "image": require("./kevin.png"),
        "linkedInURL": "https://www.linkedin.com/in/kevin-vernooij/"
    },
    "Yusuf": {
        "role": "Business Development",
        "department": "Marketing & Partnership Relations",
        "image": require("../placeholder.png"),
    },
    "Emmett Childs": {
        "role": "Business Development",
        "department": "Institutional Relations",
        "image": require("./emmett.png"),
        "linkedInURL": "https://www.linkedin.com/in/echildsplay/"
    }
}

export default team
