import clsx from "clsx"

export function Button({disabled, className, children}) {
    const classes = disabled ? "opacity-50 hover:cursor-not-allowed" : "hover:bg-blue-800"

    return (
        <button
            type="button"
            disabled={disabled}
            className={clsx("flex gap-4 justify-center items-center text-white bg-blue-700 rounded-lg",
                "text-xl font-medium p-3 px-4 sm:pt-3.5 sm:p-4 sm:px-6", classes, className)}>
            {children}
        </button>
    )
}

export function ButtonLink({href, className, children, disabled, newTab = true}) {
    const classes = disabled ? "opacity-50 hover:cursor-not-allowed" : "hover:bg-blue-800 hover:cursor-pointer"

    return (
        <a href={href} target={newTab ? "_blank" : "_self"} rel="noreferrer noopener"
           className={clsx("flex gap-4 justify-center items-center text-white bg-blue-700 rounded-lg",
               "text-xl font-medium p-3 px-4 sm:pt-3.5 sm:p-4 sm:px-6", classes, className)}>
            {children}
        </a>
    )
}
