import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTwitter, faYoutube, faTelegram, faMedium, faDiscord, faInstagram} from "@fortawesome/free-brands-svg-icons"
import {faEnvelope} from "@fortawesome/free-solid-svg-icons"

export default function Footer() {
    return (
        <footer className="w-full flex flex-col items-center bg-neutral-900 pt-9 pb-8">
            <div className="flex gap-2 sm:gap-4">
                <a href="mailto:mail@frictionless.network" target="_blank"
                   rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faEnvelope} fixedWidth
                                     className="text-lg sm:text-2xl text-neutral-400 hover:text-neutral-300"/>
                </a>

                <a href="https://t.me/frictionlessx" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTelegram} fixedWidth
                                     className="text-lg sm:text-2xl text-neutral-400 hover:text-neutral-300"/>
                </a>

                <a href="https://twitter.com/FrictionlessX" target="_blank"
                   rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTwitter} fixedWidth
                                     className="text-lg sm:text-2xl text-neutral-400 hover:text-neutral-300"/>
                </a>

                <a href="https://www.instagram.com/frictionlessnetwork" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} fixedWidth
                                     className="text-lg sm:text-2xl text-neutral-400 hover:text-neutral-300"/>
                </a>

                <a href="https://discord.gg/xyeYmB4KHW" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faDiscord} fixedWidth
                                     className="text-lg sm:text-2xl text-neutral-400 hover:text-neutral-300"/>
                </a>

                <a href="https://www.youtube.com/@frictionlessnetwork" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faYoutube} fixedWidth
                                     className="text-lg sm:text-2xl text-neutral-400 hover:text-neutral-300"/>
                </a>

                <a href="https://medium.com/@frictionless-network" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faMedium} fixedWidth
                                     className="text-lg sm:text-2xl text-neutral-400 hover:text-neutral-300"/>
                </a>

                <div className="border-l-2 border-neutral-400"/>

                <a href="https://t.me/tokenalliancelobby" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTelegram} fixedWidth
                                     className="text-lg sm:text-2xl text-neutral-400 hover:text-neutral-300"/>
                </a>
            </div>

            <p className="text-sm text-center text-neutral-500 mt-2">
                All rights reserved Frictionless-Network
            </p>
        </footer>
    )
}
