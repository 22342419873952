import Section from "./Section"
import render1 from "../assets/render-1.png"
import render2 from "../assets/render-2.png"
import render3 from "../assets/render-3.png"
import {SubTitle} from "./Typing";

export default function Reason() {
    return (
        <Section id="reason" className="justify-center gap-12">
            <SubTitle className="!text-center text-4xl sm:text-7xl lg:text-8xl">
                Why Frictionless?
            </SubTitle>

            <div className="flex flex-col justify-center items-center gap-8 lg:flex-row lg:items-stretch">
                <div className="w-full max-w-xs sm:max-w-sm text-center">
                    <div className="h-80 flex justify-center items-center border border-gray-400 p-6" style={{borderRadius: 60}}>
                        <img src={render1} className="h-full w-auto mix-blend-hard-light" alt=""/>
                    </div>

                    <p className="text-4xl text-neutral-100 font-bold mt-8">
                        Price Impact
                    </p>
                    <p className="text-2xl text-neutral-200 font-light mt-4">
                        Eliminating negative effects on asset prices by bypassing market liquidation, greatly benefiting
                        tokenized projects and companies.
                    </p>
                </div>

                <div className="w-full max-w-xs sm:max-w-sm text-center">
                    <div className="h-80 flex justify-center items-center border border-gray-400 p-8" style={{borderRadius: 60}}>
                        <img src={render2} className="h-full w-auto mix-blend-hard-light" alt=""/>
                    </div>

                    <p className="text-4xl text-neutral-100 font-bold mt-8">
                        Vesting Pressure
                    </p>
                    <p className="text-2xl text-neutral-200 font-light mt-4">
                        Stabilizing asset prices and protecting retail investors with decentralized custom vesting
                        solutions for institutional investors
                    </p>
                </div>

                <div className="w-full max-w-xs sm:max-w-sm text-center">
                    <div className="h-80 flex justify-center items-center border border-gray-400 p-8" style={{borderRadius: 60}}>
                        <img src={render3} className="h-full w-auto max-h-56 mix-blend-hard-light" alt=""/>
                    </div>

                    <p className="text-4xl text-neutral-100 font-bold mt-8">
                        Asset Ownership
                    </p>
                    <p className="text-2xl text-neutral-200 font-light mt-4">
                        Ensuring secure ownership where custody always remains with the asset holder and their
                        private wallets.
                    </p>
                </div>
            </div>
        </Section>
    )
}
