import Section from "./Section"
import Piece from "./Piece"
import {Title} from "./Typing"


export default function Whitepaper() {
    return (
        <Section id="whitepaper" secondary className="min-h-min lg:min-h-screen-3/4">
            <div className="relative flex-1 space-y-8 pl-8 lg:pl-0">
                <Title className="top-16 left-0 lg:absolute !text-start">
                    Innovation.<br/>
                    Security.<br/>
                    Future.<br/>
                    DeFi.
                </Title>

                <div className="bottom-0 right-0 max-w-xl lg:absolute xl:bottom-16 lg:max-w-md xl:max-w-2xl">
                    <p className="text-2xl text-neutral-200 font-extralight lg:text-4xl">
                        At Frictionless, we have brought together a team of experts from various industries to develop
                        and finalize our unique vision. <a href="http://tinyurl.com/FrictionlessWhitePaper"
                                                           target="_blank" rel="noopener noreferrer"
                                                           className="font-medium text-blue-600">Download</a> our
                        comprehensive white paper outlining our
                        innovative decentralized OTC and P2P solution.
                    </p>

                    <div className="flex flex-col gap-12 mt-12 sm:flex-row">
                        <a href="http://tinyurl.com/FrictionlessWhitePaper" target="_blank" rel="noopener noreferrer"
                           className="group flex gap-12">
                        <div className="text-neutral-200">
                                <p className="text-4xl font-bold">
                                    Whitepaper
                                </p>
                                <p className="text-2xl font-light">
                                    606KB
                                </p>
                            </div>
                        </a>

                        <a href="https://tinyurl.com/PDFric" target="_blank" rel="noopener noreferrer"
                           className="group flex gap-12">
                            <div className="text-neutral-200">
                                <p className="text-4xl font-bold">
                                    Pitchdeck
                                </p>
                                <p className="text-2xl font-light">
                                    1.8MB
                                </p>
                            </div>
                        </a>
                    </div>
                </div>

                <Piece number={3} className="w-[28rem] -right-64 -top-[22rem] 2xl:block"/>
            </div>
        </Section>
    )
}
