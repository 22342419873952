import Section from "./Section"
import partners from "../assets/partners"
import {Description, Title, SmallTitle} from "./Typing"
import keyDifference from "../assets/key-difference.png"
import linum from "../assets/linum.png"

export default function Partners() {
    return (
        <Section id="partner" className="min-h-screen-1/2">
            <div className="flex-1 flex justify-between items-center gap-20">
                <div className="flex flex-col gap-4 lg:max-w-5xl">
                    <Title className="mb-4">
                        Partners
                    </Title>

                    <Description>
                        A synergistic collaboration between project partners, harnessing the resources and expertise
                        available through our platform and network, culminating in shared growth and success for all
                        stakeholders.
                    </Description>

                    <div className="flex flex-wrap justify-center gap-6 mt-12 lg:gap-12 lg:justify-start">
                        {Object.entries(partners).map(([name, {url, image}]) => {
                            return <a key={url} href={url} target="_blank" rel="noopener noreferrer"
                                      className="relative group flex justify-center items-center hover:cursor-pointer">
                                <img src={image} className="h-12 w-auto opacity-80 group-hover:opacity-100 sm:h-20"
                                     alt={name}/>
                            </a>
                        })}
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-col justify-between gap-16 py-32 lg:flex-row lg:max-w-5xl">
                <div className="flex flex-col items-center gap-4">
                    <SmallTitle>
                        Incubation
                    </SmallTitle>

                    <a rel='noopener noreferrer' href="https://keydifference.com/" target="_blank" className="group">
                      <img src={keyDifference} className="h-12 w-fit sm:h-16 opacity-90 group-hover:opacity-100"
                           alt="Key Difference Labs"/>
                    </a>
                </div>

                <div className="flex flex-col items-center gap-4">
                    <SmallTitle>
                        Dev Studio
                    </SmallTitle>

                    <a rel='noopener noreferrer' href="https://linumlabs.com" target="_blank" className="group">
                        <img src={linum} className="h-12 w-fit sm:h-16"
                             alt="Linum Labs"/>
                    </a>
                </div>
            </div>
        </Section>
)
}
